<template>
  <div
    class="container"
    v-title
    id="annualStatistics"
    :data-title="$t('i18n.annualStatistics')"
  >
    <div id="outer-title">{{ $t("i18n.annualStatistics") }}</div>
    <div class="chartContain" v-loading="loading">
      <jl-chart
        v-if="!loading"
        :columns="option"
        name="option"
        @clickOnChart="clickOnChart"
      ></jl-chart>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
var allData = {};
export default {
  name: "CountYearBills",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      loading: true,
      option: {
        title: t("i18n.annualStatistics"),
        type: "bar",
        xData: [],
        series: [],
      },
    });

    const init = () => {
      let nowYear = new Date().getFullYear();
      for (let i = 2016; i <= nowYear; i++) {
        initData(i);
      }
    };

    const initData = async (year) => {
      let obj = { year: year };
      let { data } = await proxy.$api.maintenance.getCountYearOuter(obj);
      allData[year] = {};
      data.map((item) => {
        allData[year][item.name] = item.value;
      });
      setDataByYear();
    };

    const setDataByYear = () => {
      let length = new Date().getFullYear() - 2016 + 1;
      if (Object.keys(allData).length !== length) {
        return;
      }
      state.option.xData = Object.keys(allData);
      state.option.series = [];
      Object.keys(allData["2016"]).map((name) => {
        var data = [];
        state.option.xData.map((year) => {
          data.push(allData[year][name]);
        });
        var obj = {
          name: name,
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: data,
        };
        if (name.indexOf("保养") > -1) {
          obj.stack = "维保";
        }
        state.option.series.push(obj);
      });
      state.loading = false;
    };
    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.chartContain {
  height: 700px;
}
@media screen and (max-width: 768px) {
  .chartContain {
    height: 500px;
  }
}
</style>
